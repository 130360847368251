import React, {ReactElement, useMemo} from 'react';
import "./project.scss"
import {Contact} from "../../../types/types";
import Icon from "../../shared/icon/Icon";
import {getContactIcon, getTitle} from "../../../utils/contact";

interface ProjectProps {
    name: string;
    url?: string;
    links?: Contact;
}

const Project = ({url, links, name}: ProjectProps) => {
    const linkItems = useMemo(() => {
        const items: ReactElement[] = [];

        if (url) {
            items.push(
                <div className="project__link" key={url} onClick={()=> window.open(url, "_blank")}>
                    <Icon icon="bi-link-45deg" size={20}/>
                    <div className="project__link__url">Website</div>
                </div>
            )
        }

        if (links) {
            for (const [key, value] of Object.entries(links)) {
                const icon = getContactIcon(key);
                const title = getTitle(key);

                if (!icon || !title) {
                    return;
                }

                items.push(
                    <div className="project__link"  onClick={()=> window.open(value, "_blank")}>
                        {icon}
                        <div className="project__link__url">{title}</div>
                    </div>
                );
            }
        }

        return items;
    }, [url, links]);

    return (
        <div className="project">
            <div className="project__name">{name}</div>
            {linkItems}
        </div>
    );
}

Project.displayName = 'Project';

export default Project;
