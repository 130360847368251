import React, {ReactElement} from 'react';
import "./contact.scss"

interface ContactProps {
    icon: ReactElement;
    link: string;
    title: string;
    isMail?: boolean;
}

const Contact = ({icon, link, title, isMail = false}: ContactProps) => {
    const handleOpenLink = () => {
        if (isMail) {
            window.open(`mailto:${link}?subject=`);
        } else {
            window.open(link, "_blank")
        }
    }

    return (
        <div className="contact" onClick={handleOpenLink}>
            <div className="contact__icon">{icon}</div>
            <div className="contact__name">{title}</div>
        </div>
    );
}

Contact.displayName = 'Contact';

export default Contact;
