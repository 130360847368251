import React from 'react';
import "./profile.scss"

interface ProfileProps {
    image: string;
    firstName: string;
    lastName: string;
}

const Profile = ({firstName,lastName,image}:ProfileProps) => {
    return (
        <div className="profile">
            <img src={image} alt="profile"/>
<div className="profile__name">{firstName} {lastName}</div>
        </div>
    );
}

Profile.displayName = 'Profile';

export default Profile;
