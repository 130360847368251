import React from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "./home-page/HomePage";

const App = () => {
    return (
        <Routes>
            <Route path="/:gym" element={<HomePage />} />
        </Routes>
    );
}

App.displayName = 'App';

export default App;
