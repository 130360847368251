import Icon from "../components/shared/icon/Icon";

export const getContactIcon = (key: string) => {
    switch (key) {
        case 'github':
            return <Icon icon={'bi-github'} size={20}/>
        case 'instagram':
            return <Icon icon={'bi-instagram'} size={20}/>
        case 'facebook':
            return <Icon icon={'bi-facebook'} size={20}/>
        case 'tiktok':
            return <Icon icon={'bi-tiktok'} size={20}/>
        case 'youtube':
            return <Icon icon={'bi-youtube'} size={20}/>
        case 'twitter':
            return <Icon icon={'bi-twitter-x'} size={20}/>
        case 'linkedin':
            return <Icon icon={'bi-linkedin'} size={20}/>
        case 'threads':
            return <Icon icon={'bi-threads'} size={20}/>
        case 'telegram':
            return <Icon icon={'bi-telegram'} size={20}/>
        case 'mail':
            return <Icon icon={'bi-envelope-at'} size={20}/>
        default:
            return undefined;
    }
}
export const getContactLink = (key: string, name: string) => {
    switch (key) {
        case 'github':
            return `https://github.com/${name}`
        case 'instagram':
            return `https://www.instagram.com/${name}`;
        case 'facebook':
            return `https://www.facebook.com/${name}`;
        case 'tiktok':
            return `https://www.tiktok.com/@${name}`;
        case 'youtube':
            return `https://www.youtube.com/${name}`;
        case 'twitter':
            return `https://twitter.com/${name}`;
        case 'linkedin':
            return `https://linkedin.com/in/${name}`;
        case 'threads':
            return `https://www.threads.net/@${name}`;
        case 'telegram':
            return `${name}`;
            case 'mail':
            return `${name}`;
        default:
            return undefined;
    }
}

export const getTitle = (key: string) => {
    switch (key) {
        case 'instagram':
            return `Instagram`;
        case 'facebook':
            return `Facebook`;
        case 'tiktok':
            return `TikTok`;
        case 'youtube':
            return `Youtube`;
        case 'twitter':
            return `X`;
        case 'linkedin':
            return `LinkedIn`;
        case 'threads':
            return `Threads`;
        case 'telegram':
            return `Telegram`;
        case 'github':
            return 'GitHub';
        case 'mail':
            return 'E-Mail';
        default:
            return undefined;
    }
};
