import { request } from '../../utils/request';
import {BusinessCard} from "../../types/types";
import {ApiFunctionResult} from "../../types/api";

export const getData = async (id: string): Promise<ApiFunctionResult<BusinessCard>> => {
    const response = await request<BusinessCard>({
        method: 'GET',
        route: `${id}`
    });

    if (response.status === 200) {
        return { data: response.data, status: 200 };
    }

    return { status: response.status };
};
